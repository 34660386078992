"use client";

/**
 * Third-party libraries.
 */
// import { FilterOutlined as FilterOutlinedIcon } from "@ant-design/icons";
import { Alert, Button, Divider, Input, Tabs, TabsProps } from "antd";
import { useRouter, useSearchParams } from "next/navigation";
import { useMemo, useState } from "react";

/**
 * Project components.
 */
import {
  CallCompleteCard,
  CallControlLiveCard,
  CallControlRingingCard,
  CallInformationCard,
  CallRoutingCard,
  CallSummaryCard,
} from "@/components/client/call";
import { CallPastInteractionsCard } from "@/components/client/call/call-past-interactions-card";
import { CallRecordingPlayerCard } from "@/components/client/call/call-recording-player-card";
import {
  CommunicationDirection,
  CommunicationLog,
  CommunicationLogStatus,
  useCommunicationLogContext,
} from "@/components/client/communication-log";
import CommunicationLogSelectALogPlaceholder from "@/components/client/communication-log/communication-log-select-a-log-placeholder";
import ContactProfile from "@/components/client/contact/contact-profile";
import { useCallCompleteMutation } from "@/components/client/graphql";
import { ApplicationDefaultProtectedPageLayout } from "@/components/client/layout";
import { useNetworkContext } from "@/components/client/network";
import { useTwilioContext } from "@/components/client/twilio";

/**
 * Home page.
 * This is requires authentication to be accessed.
 */
function HomePage() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================
  const urlSearchParam = useSearchParams();
  const router = useRouter();

  const tabParam = urlSearchParam.get("tab");
  const items: TabsProps["items"] = [
    { key: "all", label: "All" },
    { key: "missed", label: "Missed" },
    /** Uncomment for "pending" tab */
    // { key: "pending", label: "Pending" },
  ];

  /**
   * Communication log context.
   */
  const {
    // =========================================================================
    // Communication Logs Active
    // =========================================================================
    communicationLogsActive,
    communicationLogsActiveLoading,
    // =========================================================================
    // Communication Logs Concluded
    // =========================================================================
    communicationLogsConcluded,
    communicationLogsConcludedLoading,
    communicationLogsConcludedFetchingMore,
    fetchMoreCommunicationLogsConcluded,
    hasMoreCommunicationLogsConcluded,
    setCommunicationLogsConcludedFilter,
    // =========================================================================
    // Communication Logs Missed
    // =========================================================================
    communicationLogsMissed,
    communicationLogsMissedLoading,
    communicationLogsMissedFetchingMore,
    fetchMoreCommunicationLogsMissed,
    hasMoreCommunicationLogsMissed,
    // =========================================================================
    // Selected Communication Log
    // =========================================================================
    selectedCommunicationLog,
    selectedCommunicationLogLoading,
    setSelectedCommunicationLog,
  } = useCommunicationLogContext();

  const { connected, loading: networkLoading } = useNetworkContext();
  const { deviceRegistered, deviceRegistering } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [selectedTab, setSelectedTab] = useState(tabParam || "missed");

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const [
    completeCall,
    { data: completeCallResponse, loading: callCompleting },
  ] = useCallCompleteMutation();

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  const contactProfileData = useMemo(() => {
    if (!selectedCommunicationLog) {
      return;
    }

    const contact =
      selectedCommunicationLog.direction === CommunicationDirection.INBOUND
        ? selectedCommunicationLog.fromContact
        : selectedCommunicationLog.toContact;

    return {
      ...contact,
      id: contact?.id || selectedCommunicationLog.clientPhoneNumber,
      displayName:
        contact?.displayName || selectedCommunicationLog.clientPhoneNumber,
      phoneNumbers: contact?.phoneNumbers || [],
      photoUrl: contact?.photoUrl,
      email: contact?.email,
      issues: contact?.issues || [],
    };
  }, [selectedCommunicationLog]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <ApplicationDefaultProtectedPageLayout>
      <div className="flex h-full">
        <div className="grid h-full w-full grid-cols-12 grid-rows-1">
          {/* 2nd Panel (Communication Log) */}
          <div className="col-span-6 flex h-full flex-col lg:col-span-4">
            {((!networkLoading && !connected) ||
              (!deviceRegistering && !deviceRegistered)) && (
              <Alert
                className="w-full"
                message="Disconnected: Please reload the page."
                type="error"
                showIcon
                action={
                  <Button
                    onClick={() => {
                      window.location.reload();
                    }}
                    size="small"
                    type="primary"
                  >
                    Reload
                  </Button>
                }
              />
            )}
            <CommunicationLog
              classNames="!h-auto"
              data={communicationLogsActive}
              hideListStatusIndicator
              loading={communicationLogsActiveLoading}
              onLogClick={({ communicationLog }) => {
                setSelectedCommunicationLog(communicationLog);
              }}
              onMaxScroll={fetchMoreCommunicationLogsConcluded}
            />
            {/* <Divider className="!m-0" /> */}
            <div className="inline-flex h-16 w-full flex-col items-start justify-center gap-2 border-b border-r border-t border-slate-200 bg-white p-4">
              <div className="inline-flex items-center justify-start gap-2 self-stretch">
                <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1">
                  <Input.Search
                    allowClear
                    className="rounded border border-slate-200 bg-violet-50"
                    enterButton
                    // loading
                    placeholder="Search"
                    onSearch={(value) => {
                      setCommunicationLogsConcludedFilter({
                        from: value,
                        to: value,
                      });
                    }}
                    variant="filled"
                  />
                </div>
                {/* <Button icon={<FilterOutlined />} type="text" /> */}
              </div>
            </div>
            {/* Tabs */}
            <div className="bg-white px-4">
              <Tabs
                defaultActiveKey="all"
                activeKey={selectedTab}
                items={items}
                onChange={(key) => {
                  router.push(`/?tab=${key}`);
                  setSelectedTab(key);
                }}
              />
            </div>

            <Divider className="-mt-1" />

            {selectedTab === "all" ? (
              <CommunicationLog
                classNames="flex-grow !h-auto !min-h-[100px]"
                data={communicationLogsConcluded}
                hasMoreData={hasMoreCommunicationLogsConcluded}
                loading={communicationLogsConcludedLoading}
                loadingMoreData={communicationLogsConcludedFetchingMore}
                onLogClick={({ communicationLog }) => {
                  setSelectedCommunicationLog(communicationLog);
                }}
                onMaxScroll={fetchMoreCommunicationLogsConcluded}
              />
            ) : (
              <CommunicationLog
                classNames="flex-grow !h-auto !min-h-[100px]"
                data={communicationLogsMissed}
                hasMoreData={hasMoreCommunicationLogsMissed}
                loading={communicationLogsMissedLoading}
                loadingMoreData={communicationLogsMissedFetchingMore}
                onLogClick={({ communicationLog }) => {
                  setSelectedCommunicationLog(communicationLog);
                }}
                onMaxScroll={fetchMoreCommunicationLogsMissed}
              />
            )}
          </div>

          {/* 3rd Panel (Placeholder) */}
          <div
            className={`col-span-6 inline-flex h-full w-full flex-col items-center justify-center bg-neutral-surface-grey lg:col-span-8 ${
              !selectedCommunicationLog ? "" : "hidden"
            }`}
          >
            <CommunicationLogSelectALogPlaceholder />
          </div>

          {/* 3rd Panel (Current Call Info) */}
          <div
            className={`col-span-6 flex h-full flex-col overflow-hidden lg:col-span-4 ${
              !!selectedCommunicationLog ? "" : "hidden"
            }`}
          >
            <CallControlRingingCard data={selectedCommunicationLog} />
            <CallCompleteCard
              callback={{
                onCompleteCall: async () => {
                  if (!selectedCommunicationLog?.callSid) {
                    return;
                  }

                  await completeCall({
                    variables: {
                      input: {
                        callSid: selectedCommunicationLog.callSid,
                      },
                    },
                  });
                },
              }}
              data={{
                callSid: selectedCommunicationLog?.callSid!,
                clientName: selectedCommunicationLog?.clientName,
                clientPhoneNumber:
                  selectedCommunicationLog?.clientPhoneNumber ?? "",
                // Convert to milliseconds.
                duration: selectedCommunicationLog?.duration
                  ? selectedCommunicationLog?.duration * 1000
                  : 0,
              }}
              disabled={selectedCommunicationLogLoading || callCompleting}
              loading={callCompleting}
              visible={
                !!selectedCommunicationLog?.callSid &&
                selectedCommunicationLog?.status ===
                  CommunicationLogStatus.WRAPPING_UP
              }
            />
            <CallControlLiveCard
              data={selectedCommunicationLog}
              visible={
                selectedCommunicationLog?.status ===
                CommunicationLogStatus.ONGOING
              }
            />
            <div
              className={`flex h-full w-full flex-col items-center gap-4 overflow-y-auto bg-[#fafbff] p-4 ${selectedCommunicationLog?.status === CommunicationLogStatus.RINGING ? "hidden" : ""}`}
            >
              <CallInformationCard
                visible={
                  !!selectedCommunicationLog &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.RINGING &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.ONGOING &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.WRAPPING_UP
                }
              />
              <CallRecordingPlayerCard
                loading={selectedCommunicationLogLoading}
                visible={
                  selectedCommunicationLog?.status ===
                    CommunicationLogStatus.WRAPPING_UP ||
                  selectedCommunicationLog?.status ===
                    CommunicationLogStatus.COMPLETED
                }
              />
              <CallSummaryCard
                data={{
                  callSid: selectedCommunicationLog?.callSid!,
                  summary: selectedCommunicationLog?.summary,
                  callStatus: selectedCommunicationLog?.status,
                }}
                disabled={
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.ONGOING &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.WRAPPING_UP &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.COMPLETED
                }
                visible={
                  !!selectedCommunicationLog?.callSid &&
                  (selectedCommunicationLog?.status ===
                    CommunicationLogStatus.ONGOING ||
                    selectedCommunicationLog?.status ===
                      CommunicationLogStatus.WRAPPING_UP ||
                    selectedCommunicationLog?.status ===
                      CommunicationLogStatus.COMPLETED)
                }
              />
              <CallRoutingCard
                loading={selectedCommunicationLogLoading}
                routings={selectedCommunicationLog?.routings ?? []}
                visible={
                  !!selectedCommunicationLog &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.RINGING &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.ONGOING &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.WRAPPING_UP
                }
              />
            </div>
          </div>

          {/* 4th Panel (Other Call Info) */}
          <div
            className={`hidden border-l lg:col-span-4 ${
              // Hide when nothing is selected.
              !!selectedCommunicationLog ? "lg:block" : "hidden"
            }`}
          >
            <div className="flex h-full w-full flex-col items-center gap-4 overflow-y-auto bg-[#fafbff] p-4">
              <ContactProfile
                visible={!!selectedCommunicationLog}
                data={contactProfileData}
              />
              <CallPastInteractionsCard
                // Always visible.
                visible={!!selectedCommunicationLog}
              />
            </div>
          </div>
        </div>
      </div>
    </ApplicationDefaultProtectedPageLayout>
  );
}

export default HomePage;
