"use client";

/**
 * Third-party libraries.
 */
import BulletList from "@tiptap/extension-bullet-list";
import Image from "@tiptap/extension-image";
import Placeholder from "@tiptap/extension-placeholder";
import { EditorProps } from "@tiptap/pm/view";
import {
  BubbleMenu,
  Content,
  EditorContent,
  FloatingMenu,
  useEditor,
  UseEditorOptions,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useEffect } from "react";

/**
 * Project components.
 */
import { getHotkeyHandler } from "@/components/client/hooks/use-hotkeys";
import { UnorderedListOutlined } from "@ant-design/icons";
import { ShiftEnterHandlerExtension } from "./custom-extensions";
import "./rich-text-editor.css";

/** Type for the RichTextEditor component. */
type RichTextEditorProps = {
  /**
   * The INITIAL content of the editor.
   *
   * IMPORTANT:
   * 1. Avoid setting this `onUpdate`. Why? Because you lose cursor focus
   * when you try to re-set this everytime.
   * 2. Only set this to a new value for completely new content (when cursor position doesn't matter.)
   * 3. If you need to watch this, use `onUpdate` and set to a separate state instead.
   * 4. You can always get the final value with `editor.getHTML()`.
   */
  content?: Content;
  /**  The placeholder text for the editor. */
  placeholder?: string;
  /** A function that should return if the editor is editable. @defaultValue () => `true` */
  editable?: EditorProps["editable"];
  /**
   * Callback event triggered when the editor loses focus.
   */
  onBlur?: () => void;
  /**
   * A way for us to get the tiptap editor instance from the parent component.
   *
   * @example
   * const editorRef = useRef<Editor | null>(null);
   *
   * // 1. Reference the editor instance from the parent component
   * <RichTextEditor
   *   onCreate={({ editor }) => {
   *     editorRef.current = editor;
   *   }}
   * />
   *
   * // 2. Now you can perform any tiptap commands. (e.g. focus from a button in parent).
   * <Button onClick={() => {
   *   editorRef.current?.focus('all');
   * }}>Focus The Editor!</Button>
   */
  onCreate?: UseEditorOptions["onCreate"];
  /**
   * Gets called when the editor updates.
   *
   * IMPORTANT: Don't try to set `content` from here like when you use <input onChange={...} /> in regular React.
   * Reason is because you lose cursor focus when you try to re-set this everytime.
   */
  onUpdate?: UseEditorOptions["onUpdate"];
  /**
   * MOD means CMD on Mac and CTRL on Windows.
   */
  onHotkey_MOD_S?: (event: KeyboardEvent) => void;
  /**
   * This function gets called when the hotkey "Enter" is pressed.
   */
  onHotkey_ENTER?: (event: KeyboardEvent) => void;
  /**
   * This function gets called when the hotkey "Escape" is pressed.
   */
  onHotkey_ESC?: (event: KeyboardEvent) => void;
};

/**
 * A rich text editor component for all WYSIWYG (What You See Is What You Get) editor
 * usecases in the CCC frontend.
 *
 * Keyboard shortcuts also work out of the box here. See more: https://tiptap.dev/docs/editor/core-concepts/keyboard-shortcuts#page-title
 *
 */
export function RichTextEditor(props: RichTextEditorProps) {
  /**
   * We personally prefer the hook approach.
   * But if you want to break the RichTextEditor into multiple components, you can use the provider approach.
   * https://tiptap.dev/docs/editor/getting-started/install/react#consume-the-editor-context-in-child-components
   *
   * Using the provider approach will allow you to access the `editor` instance using a `useCurrentEditor` hook.
   * For hook approach, just pass this around, no problem.
   */
  const editor = useEditor(
    {
      extensions: [
        StarterKit.configure({
          hardBreak: false, // Hard break adds SHIFT + ENTER to make <br />, which we don't want.
        }),
        BulletList,
        Image.configure({
          /** Enabling to true allows you to paste and drag images from the clipboard. */
          allowBase64: true,
        }),
        Placeholder.configure({
          showOnlyWhenEditable: false,
          placeholder: props.placeholder,

          /** before:content-[attr(data-placeholder)] is needed so it displays in HTML. */
          emptyEditorClass:
            "cursor-text before:content-[attr(data-placeholder)] before:absolute before:top-0 before:left-0 before:opacity-50 before-pointer-events-none",
        }),
        ShiftEnterHandlerExtension,
      ],
      content: props.content,
      editorProps: {
        attributes: {
          /**
           * We're using tailwind-typography to style all the Rich Text.
           * You can customize it in `tailwind.config.ts > theme > extend > typography`.
           */
          class: "prose prose-sm focus:outline-none",
        },
        editable: props.editable ?? (() => true),
      },
      onCreate: props.onCreate,
      onUpdate: props.onUpdate,
      onBlur: props.onBlur,
    },
    // Ideally don't use the dependency array as it will cause the editor to re-render.
  );

  // Reinitialize content when it changes.
  useEffect(() => {
    if (editor) {
      editor.commands.setContent(props.content ?? null);
    }
  }, [editor, props.content]);

  return (
    <div>
      {/* Appears on a new line. Know more: https://tiptap.dev/docs/editor/getting-started/style-editor/custom-menus#floating-menu */}
      <FloatingMenu
        editor={editor}
        /** Show on <br /> and empty <p> tags. */
        shouldShow={(_props) => {
          if (_props.editor.isEditable) {
            // These 3 lines are how to get the current node in TipTap.
            // Learn more: https://github.com/ueberdosis/tiptap/discussions/3787#discussioncomment-10390236
            const state = _props.editor.view.state;
            const from = state.selection.from;
            const currentPos = state.doc.resolve(from);

            /** Empty paragraph. <p></p> */
            if (
              _props.editor.isActive("paragraph") &&
              currentPos.node().content.size <= 0
            )
              return true;

            /** A linebreak. <br />.
             * We can't use _props.editor.isActive("hardBreak") because making linebreaks
             * also means we're focusing on the next node (e.g. the parent paragraph).
             *
             * Luckily, we can always check the last child of the current paragraph, if it's a "hardBreak".
             */
            if (currentPos.node().lastChild?.type.name === "hardBreak")
              return true;
          }
          return false;
        }}
        className="flex gap-x-1 rounded-md bg-gray-200 bg-gradient-to-br from-tpl-red to-tpl-red-dark p-1 text-white shadow-md"
        tippyOptions={{
          duration: 250,
          animation: "fade",
        }}
      >
        <MenuTipButton
          icon={"H1"}
          onClick={() =>
            editor?.chain()?.focus()?.toggleHeading({ level: 1 })?.run()
          }
          active={editor?.isActive("heading", { level: 1 })}
        />
        <MenuTipButton
          icon={"H2"}
          onClick={() =>
            editor?.chain()?.focus()?.toggleHeading({ level: 2 })?.run()
          }
          active={editor?.isActive("heading", { level: 2 })}
        />
        <MenuTipButton
          icon={"H3"}
          onClick={() =>
            editor?.chain()?.focus()?.toggleHeading({ level: 3 })?.run()
          }
          active={editor?.isActive("heading", { level: 3 })}
        />
        <MenuTipButton
          icon={<UnorderedListOutlined />}
          onClick={() => editor?.chain()?.focus()?.toggleBulletList()?.run()}
          active={editor?.isActive("bulletList")}
        />
        <MenuTipButton
          icon={<UnorderedListOutlined />}
          onClick={() => editor?.chain()?.focus()?.toggleOrderedList()?.run()}
          active={editor?.isActive("orderedList")}
        />
      </FloatingMenu>

      {/* Appears after selecting text. Know more: https://tiptap.dev/docs/editor/getting-started/style-editor/custom-menus#bubble-menu */}
      <BubbleMenu
        editor={editor}
        className="flex gap-x-1 rounded-md bg-gray-200 bg-gradient-to-br from-tpl-red to-tpl-red-dark p-1 text-white shadow-md"
        tippyOptions={{
          duration: 250,
          animation: "fade",
        }}
      >
        <MenuTipButton
          icon={"B"}
          onClick={() => editor?.chain()?.focus()?.toggleBold()?.run()}
          active={editor?.isActive("bold")}
        />
        <MenuTipButton
          icon={"• -"}
          onClick={() => editor?.chain()?.focus()?.toggleBulletList()?.run()}
          active={editor?.isActive("bulletList")}
        />
        <MenuTipButton
          icon={<span className="text-xs">{"1. -"}</span>}
          onClick={() => editor?.chain()?.focus()?.toggleOrderedList()?.run()}
          active={editor?.isActive("orderedList")}
        />
      </BubbleMenu>

      <EditorContent
        editor={editor}
        className="p-2.5"
        tabIndex={-1}
        onKeyDown={getHotkeyHandler([
          ["Enter", props.onHotkey_ENTER ?? (() => {})],
          ["escape", props.onHotkey_ESC ?? (() => {})],
        ])}
      />
    </div>
  );
}

/** Type for the MenuTipButton component. */
type MenuTipButtonProps = {
  /** The icon to render for the menu button. */
  icon: React.ReactNode;
  /** @defaultValue `false` */
  active?: boolean;
  /** The callback to be called when the button is clicked. */
  onClick: () => void;
};

/** A single menu tip button with proper styles. */
export function MenuTipButton(props: MenuTipButtonProps) {
  return (
    <button
      className={`flex h-6 w-6 items-center justify-center rounded-md p-1 hover:bg-white/20 ${props.active ? "bg-white/20" : ""}`}
      onClick={props.onClick}
    >
      {props.icon}
    </button>
  );
}
