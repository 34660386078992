/**
 * Third-party libraries.
 */
import { Divider } from "antd";
import { useEffect, useRef, useState } from "react";

import Collapsible from "@/components/client/collapse/collapsible";
import {
  CommunicationLogInboundCallStatusMapper,
  CommunicationLogOutboundCallStatusMapper,
  useCommunicationLogContext,
} from "@/components/client/communication-log";
import { Empty } from "@/components/client/empty/empty";
import {
  CallDirection,
  CallStatus,
  usePastInteractionsQuery,
} from "@/components/client/graphql";
import { Icon } from "@/components/client/icon";
import { Loading } from "@/components/client/loading";
import { RichTextEditor } from "@/components/client/rich-text/rich-text-editor";
import { formatDate } from "@/components/common/time/utilities/time-utility";
import { CaretDownOutlined } from "@ant-design/icons";
import { CallInformationCardField } from "./call-information-card-field";

/**
 * Call Past Interactions Card Props.
 */
type CallPastInteractionsCardProps = {
  /**
   * Indicates that the card is visible.
   */
  visible?: boolean;
};

export function CallPastInteractionsCard(props: CallPastInteractionsCardProps) {
  const { selectedCommunicationLog } = useCommunicationLogContext();

  /** Expanded state of the past interactions LIST. */
  const [expanded, setExpanded] = useState(false);

  // ===========================================================================
  // Queries
  // ===========================================================================

  /**
   * Query that contains the past interactions with `clientPhoneNumber` based
   * on the `to` OR `from` record.
   *
   * This will contain the currently selected call as well because the `endDate` is inclusive of the current call.
   *
   * TODO: Add pagination.
   */
  const pastInteractionsQuery = usePastInteractionsQuery({
    skip:
      !selectedCommunicationLog?.clientPhoneNumber ||
      !selectedCommunicationLog?.date,
    variables: {
      clientPhoneNumber: selectedCommunicationLog?.clientPhoneNumber,
    },
  });

  // ===========================================================================
  // Derived States
  // ===========================================================================

  /** Disable when there are lte 3 interactions. */
  const collapsibleIsDisabled =
    pastInteractionsQuery?.data?.calls &&
    pastInteractionsQuery?.data?.calls?.length <= 3;

  // ===========================================================================
  // Lifecycle
  // ===========================================================================
  useEffect(() => {
    if (selectedCommunicationLog?.id) {
      setExpanded(false);
    }
  }, [selectedCommunicationLog?.id]);

  // Hide if not visible.
  if (!props.visible) {
    return null;
  }

  return (
    <div className="flex animate-slide-left flex-col items-start justify-start self-stretch rounded-md border border-slate-200 bg-white">
      <div
        className="inline-flex items-center justify-between self-stretch border-b border-slate-200 p-4"
        style={{
          borderBottom: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        <div className="text-tpl-navy">Past Interactions</div>

        {/* Filter is out of scope for now. */}
        {/* <Button
          disabled
          type="text"
          className="relative w-6 h-6 flex justify-center items-center cursor-not-allowed opacity-60"
        >
          <Icon src="filter" className="absolute text-xl" />
        </Button> */}
      </div>

      {!pastInteractionsQuery.loading &&
        pastInteractionsQuery?.data?.calls?.length === 0 && (
          <Empty label="No Interactions" className="py-4" />
        )}

      {pastInteractionsQuery.loading && (
        <div
          className="flex w-full items-center justify-center"
          style={{ height: 150 }}
        >
          <Loading />
        </div>
      )}

      {pastInteractionsQuery?.data?.calls && (
        <div className="relative w-full">
          <Collapsible
            collapsedHeight="250px"
            /**
             * Always expanded. We only change the slice for now.
             */
            collapsed={false}
            className="flex w-full flex-col gap-y-4 overflow-hidden p-4"
            /**
             * Dynamic Height gives this the animation we need when expanding.
             * Or changing the amount of list items.
             */
            dynamicHeight
          >
            {/* LIST */}
            {pastInteractionsQuery.data.calls
              .slice(0, expanded ? undefined : 3)
              .map((_call) => (
                <PastInteractionItemCard
                  key={_call.id}
                  direction={_call.direction}
                  status={_call.status}
                  summary={_call.summary}
                  dateStarted={_call.date}
                  agentName={_call.user?.profile?.fullName ?? ""}
                />
              ))}
          </Collapsible>

          {/* COLLAPSE BUTTON, there's no UI on Figma for this so feel free to change it. */}
          {!collapsibleIsDisabled && (
            <button
              className="group absolute bottom-0 flex w-full items-center justify-center p-2"
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              <span className="flex h-5 w-5 items-center justify-center rounded-md border bg-white transition group-hover:scale-110">
                <CaretDownOutlined
                  className={`transition ${expanded ? "rotate-180" : ""}`}
                />
              </span>
            </button>
          )}
        </div>
      )}
    </div>
  );
}

// ===========================================================================
// Past Interaction Card
// ===========================================================================

/** Props for PastInteractionItemCard */
type PastInteractionCardItemProps = {
  status: CallStatus;
  direction: CallDirection;
  summary?: string | null;
  dateStarted: Date | string;
  agentName: string;
};

/** A single card inside past interactions list. */
export function PastInteractionItemCard({
  status,
  direction,
  summary,
  dateStarted,
  agentName,
}: PastInteractionCardItemProps) {
  /** Expanded state of the past interactions card ITEM SUMMARY. */
  const [expanded, setExpanded] = useState(false);

  /** True if css clamp is active. */
  const [clampIsActive, setClampIsActive] = useState(false);

  const clampedElementRef = useRef<HTMLSpanElement>(null);

  /** Past interactions card title based on call status. */
  const cardTitle = (function () {
    if (direction === CallDirection.Inbound) {
      const mappedStatus =
        CommunicationLogInboundCallStatusMapper[
          status as keyof typeof CommunicationLogInboundCallStatusMapper
        ];

      if (mappedStatus) {
        return `${mappedStatus} - ${direction}`;
      }

      return "Unknown Call";
    } else if (direction === CallDirection.Outbound) {
      const mappedStatus =
        CommunicationLogOutboundCallStatusMapper[
          status as keyof typeof CommunicationLogOutboundCallStatusMapper
        ];

      if (mappedStatus) {
        return `${mappedStatus} - ${direction}`;
      }

      return "Unknown Call";
    }
  })();

  /** Card's top-right badge based on call status. */
  const badge = (function () {
    switch (status) {
      case CallStatus.Completed:
        return null;
      /**
       * "Resolved" tag is temporary and currently out of scope.
       * https://thepiquelab.atlassian.net/browse/ARCD-4341
       */
      // <Tag color="success" className="!text-xs mx-0 !me-0">
      //   Resolved
      // </Tag>
      default:
        return null;
    }
  })();

  /** Color of the phone icon based on call status. */
  const phoneIconColorClass = (function () {
    switch (status) {
      case CallStatus.Completed:
        return "!text-semantic-green";
      case CallStatus.Missed:
      case CallStatus.Canceled:
        return "!text-semantic-red";
      default:
        return "";
    }
  })();

  // For detecting if CSS line-clamp-3 is active (e.g. currently showing `...` via CSS).
  // When active (clamped):
  // - Collapsed: Show 3 lines
  // - Expanded: Show all lines
  // When not active (not clamped):
  // - Collapsed: Show all lines
  // - Expanded: Show all lines
  useEffect(() => {
    if (!clampedElementRef.current) return;

    /**
     * To resolve an eslint warning when trying to access the ref in the cleanup.
     * Solution is to make declare a local variable and use that in the cleanup instead.
     * This is because accessing `clampedElementRef.current` directly is erratic.
     */
    let _clampedElementRef = clampedElementRef.current;

    const isTextClamped = (elm: HTMLSpanElement) =>
      elm.scrollHeight > elm.clientHeight;

    const observer = new ResizeObserver((e) => {
      if (!clampedElementRef.current) return;

      setClampIsActive(isTextClamped(clampedElementRef.current));
    });

    observer.observe(_clampedElementRef);

    return () => {
      observer.unobserve(_clampedElementRef);
    };
  }, []);

  return (
    <div className="bg-neutral-surface-gray flex flex-col gap-y-2 rounded border border-[#E8EBF1] bg-neutral-surface-grey p-3 text-tpl-navy">
      <header className="flex w-full flex-col gap-y-2">
        <div className="flex items-center justify-between gap-x-1">
          <div className="flex items-center gap-x-1">
            <Icon src="phone" className={`!text-sm ${phoneIconColorClass}`} />

            <span className="text-sm text-tpl-navy">{cardTitle}</span>
          </div>

          {badge}
        </div>

        <CallInformationCardField
          label="Date"
          value={formatDate(dateStarted, { format: "MM/D/YYYY h:mma" })}
        />

        {agentName && (
          <CallInformationCardField label="Agent" value={agentName} />
        )}
      </header>

      {summary && (
        <>
          <Divider className="!my-0" />
          <footer className="relative flex flex-col gap-y-2">
            <span className="text-sm">Call Summary:</span>
            {/* <span
              ref={clampedElementRef}
              className={`text-xs ${expanded ? "" : "line-clamp-3"}`}
            >
              {summary}
            </span> */}
            <span
              ref={clampedElementRef}
              className={`${expanded ? "" : "line-clamp-3"}`}
            >
              <RichTextEditor content={summary} editable={() => false} />
            </span>

            {clampIsActive && !expanded && (
              <span className="absolute bottom-0 right-0 bg-neutral-surface-grey text-sm">
                ...
                <button
                  className="text-semantic-blue"
                  onClick={() => {
                    setExpanded(true);
                  }}
                >
                  See More
                </button>
              </span>
            )}

            {expanded && (
              <span className="right-0 flex justify-end bg-neutral-surface-grey text-sm">
                <button
                  className="text-semantic-blue"
                  onClick={() => {
                    setExpanded(false);
                  }}
                >
                  Collapse
                </button>
              </span>
            )}
          </footer>
        </>
      )}
    </div>
  );
}
