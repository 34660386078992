import { useCommunicationLogContext } from "@/components/client/communication-log";
import { DateUtility } from "@/components/common/utilities";
import { CallInformationCardField } from "./call-information-card-field";

// =============================================================================
// Call Information Card Field
// =============================================================================

type CallInformationCardFieldProps = {
  /**
   * Label for the field.
   */
  label: string;
  /**
   * Value for the field.
   */
  value?: string | number;
};

// =============================================================================
// Call Information Card
// =============================================================================

/**
 * Call Information Card Props.
 */
export type CallInformationCardProps = {
  /**
   * Indicates that the call information card is visible.
   */
  visible?: boolean;
};

/**
 * A small card that displays information about a call.
 */
export function CallInformationCard({ visible }: CallInformationCardProps) {
  // ===========================================================================
  // ===========================================================================
  // Contexts
  // ===========================================================================
  // ===========================================================================

  const { selectedCommunicationLog } = useCommunicationLogContext();

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!visible) {
    return null;
  }

  return (
    <div
      className={`flex animate-slide-left flex-col items-start justify-start self-stretch rounded-md border border-neutral-mid-grey bg-white ${
        !!selectedCommunicationLog ? "" : "hidden"
      }`}
    >
      <div
        className="flex flex-col items-start justify-start gap-4 self-stretch px-4 py-3"
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="text-sm font-semibold leading-[17.50px] text-tpl-navy">
          Call Information
        </div>
      </div>
      <div className="flex flex-col items-start justify-start gap-2 self-stretch p-4">
        <CallInformationCardField
          label="From"
          value={selectedCommunicationLog?.from}
        />
        <CallInformationCardField
          label="To"
          value={selectedCommunicationLog?.to}
        />
        <CallInformationCardField
          label="Agent"
          value={
            selectedCommunicationLog?.user
              ? selectedCommunicationLog.user.name
              : "-"
          }
        />
        <CallInformationCardField
          label="Date"
          value={
            selectedCommunicationLog?.date
              ? DateUtility.getDate({
                  date: selectedCommunicationLog?.date,
                })
              : "-"
          }
        />
        <CallInformationCardField
          label="Start"
          value={
            selectedCommunicationLog?.dateStarted
              ? DateUtility.getTime({
                  date: selectedCommunicationLog?.dateStarted,
                })
              : "-"
          }
        />
        <CallInformationCardField
          label="End"
          value={
            selectedCommunicationLog?.dateEnded
              ? DateUtility.getTime({
                  date: selectedCommunicationLog?.dateEnded,
                })
              : "-"
          }
        />
        <CallInformationCardField
          label="Direction"
          value={selectedCommunicationLog?.direction}
        />
        <CallInformationCardField
          label="Duration"
          value={selectedCommunicationLog?.duration + "s"}
        />
      </div>
    </div>
  );
}
